import { HashLink } from "react-router-hash-link";

const About: React.FC = () => {
  return (
    <section
      className="section"
      id="about"
      style={{ backgroundColor: "#1f1e1e" }}
    >
      <div className="about">
        <div className="about__heading" data-aos="fade-up">
          <p className="about__heading--paragraph">
            Наша компания гордится высоким качеством своей продукции. Мы строго
            <br />
            следим за производством и контролируем каждый этап, начиная от
            выбора <br />
            высококачественных ингредиентов и заканчивая упаковкой готовых{" "}
            <br />
            продуктов.
          </p>
          <span className="section__title">о нас</span>
        </div>
        <div className="about__container">
          <div className="about__half" />
          <div className="about__half" />
        </div>
        <div className="about__bottom">
          <div className="about__bottom--half">
            <span>Market Team</span>
            <div className="about__social">
              <HashLink
                to={"https://vk.com/mixterra_ru"}
                target="_blank"
                className="about__social--item"
              >
                <i className="bx bxl-vk"></i>
              </HashLink>
              <HashLink
                to={"https://wa.me/79912429405"}
                target="_blank"
                className="about__social--item"
              >
                <i className="bx bxl-whatsapp"></i>
              </HashLink>
              <HashLink
                to={"https://instagram.com/mixterra_ru?igshid=MzRlODBiNWFlZA=="}
                target="_blank"
                className="about__social--item"
              >
                <i className="bx bxl-instagram"></i>
              </HashLink>
            </div>
          </div>
          <div className="about__bottom--half">
            <p className="about__heading--paragraph" style={{ margin: 0 }}>
              Мы используем только проверенные и сертифицированные ингредиенты,
              чтобы обеспечить максимальную безопасность и эффективность нашей
              продукции. Мы работаем только с надежными производствами, которые
              соответствуют высоким стандартам качества и имеют все необходимые
              лицензии и сертификаты.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
