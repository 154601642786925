import { HashLink } from "react-router-hash-link";

const Privileges: React.FC = () => {
  return (
    <section className="section" id="privileges" style={{ paddingBottom: 0 }}>
      <div className="privileges">
        <span className="section__title">почему мы?</span>
        <div className="privileges__main">
          <div className="privileges__main--reason">
            <span>лидеры рынка по косметике и БАДам</span>
          </div>
          <div className="privileges__main--reason">
            офисы в РФ и Казахстане
          </div>
        </div>
        <div className="privileges__secondary">
          <div className="privileges__secondary--reason">
            <span>01</span>
            <p>
              Сегодня мы являемся лидерами рынка космецевтики и БАД,
              <br /> наша продукция пользуется большим спросом среди
              <br />
              покупателей.
            </p>
          </div>
          <div className="privileges__secondary--reason">
            <span>02</span>
            <p>
              Мы обеспечиваем хорошее соотношение цены
              <br /> и качества, что позволяет нам сохранять лояльность
              <br /> наших покупателей.
            </p>
          </div>
        </div>
        <div className="privileges__secondary">
          <div className="privileges__secondary--reason">
            <span>03</span>
            <p>
              Мы не останавливаемся и хотим развивать новые бренды,
              <br /> постоянно находясь в поиске уникальных составов <br /> и
              формул для поддержания красоты и здоровья.
            </p>
          </div>
          <div className="privileges__secondary--reason">
            <span>04</span>
            <p>
              Благодаря контролю над производством и сотрудничеству
              <br /> с надежными партнерами, мы уверены в высоком качестве{" "}
              <br />
              нашей продукции, которая приносит пользу и ощутимый <br />
              результат для здоровья и красоты.
            </p>
          </div>
        </div>
        <div className="privileges__button">
          <HashLink to={"#application"} className="btn btn--white">
            стать партнером
          </HashLink>
        </div>
      </div>
    </section>
  );
};

export default Privileges;
