import { HashLink } from "react-router-hash-link";
const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <span className="section__title">Market Team</span>
      <div className="footer__content">
        <div className="footer__list">
          <span className="footer__list--item">+7 499 350 7432</span>
          <HashLink
            to={"mailto:info@kvdteam.com"}
            className="footer__list--item"
          >
            info@kvdteam.com
          </HashLink>
          <span className="footer__list--item">
            125039, г. Москва, Пресненская набережная, дом 10, стр. 2.
          </span>
        </div>
        <div className="footer__list">
          <HashLink to={"/#partners"} className="footer__list--item">
            партнеры
          </HashLink>
          <HashLink to={"/#about"} className="footer__list--item">
            о нас
          </HashLink>
          <HashLink to={"/#services"} className="footer__list--item">
            услуги
          </HashLink>
          <HashLink to={"/#application"} className="footer__list--item">
            контакты
          </HashLink>
        </div>
        <div className="footer__right">
          <div className="footer__social">
            <HashLink
              to={"https://vk.com/mixterra_ru"}
              target="_blank"
              className="footer__social--item"
            >
              <i className="bx bxl-vk"></i>
            </HashLink>
            <HashLink
              to={"https://wa.me/79912429405"}
              target="_blank"
              className="footer__social--item"
            >
              <i className="bx bxl-whatsapp"></i>
            </HashLink>
            <HashLink
              to={"https://instagram.com/mixterra_ru?igshid=MzRlODBiNWFlZA=="}
              target="_blank"
              className="footer__social--item"
            >
              <i className="bx bxl-instagram"></i>
            </HashLink>
          </div>
          <HashLink to="#application" className="btn btn--white">
            Стать партнером
          </HashLink>
        </div>
      </div>
      <div className="footer__line"></div>
      <div className="footer__bottom">
        <span>
          ООО "КДВ МАРКЕТ ТИМ" 2023. Все права защищены <br />
          Политика конфиденциальности
        </span>
        <span>Разработка сайта</span>
      </div>
    </footer>
  );
};

export default Footer;
