import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
const Application: React.FC = () => {
  const sendRequest = (e: React.SyntheticEvent) => {
    const form = document!.querySelector(
      ".application__form"
    ) as HTMLFormElement;
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
      phone: { value: string };
    };
    const name = target.name.value;
    const email = target.email.value;
    const phone = target.phone.value;

    if (name && email && phone) {
      emailjs
        .sendForm(
          "market-cosmetics",
          "market-cosmetics",
          form,
          "user_Ra8roTJE5NBAXZq7eRSsY"
        )
        .then(
          (result) => {
            toast.success("Заявка отправлена");
          },
          (error) => {
            toast.error("Произошла ошибка"); //for smooth user experience
          }
        );
      form.reset();
    }
  };

  return (
    <section
      className="section"
      id="application"
      style={{ backgroundColor: "#F4F4F4" }}
    >
      <div className="application">
        <div className="application__half" />

        <div className="application__half">
          <div className="application__half--heading">
            <span className="section__title" style={{ color: "#1f1e1e" }}>
              оставьте заявку
            </span>
            <p>
              Наш сотрудник свяжется с вами для обсуждения деталей
              сотрудничества
              <br />и ответит на все ваши вопросы, или напишите нам в социальных
              сетях.
            </p>
          </div>

          <form className="application__form" onSubmit={sendRequest}>
            <input
              placeholder="контактное лицо (Ваше имя)"
              className="application__form--input"
              id="name"
              type="text"
              name="name"
              spellCheck="false"
              autoComplete="false"
              required={true}
            />
            <input
              placeholder="номер телефона"
              className="application__form--input"
              id="phone"
              type="text"
              name="phone"
              spellCheck="false"
              autoComplete="false"
              required={true}
            />
            <input
              placeholder="электронная почта"
              className="application__form--input"
              id="email"
              type="email"
              name="email"
              spellCheck="false"
              autoComplete="false"
              required={true}
            />

            <button type="submit" className="btn">
              стать партнером
            </button>
          </form>
        </div>
      </div>
      <ToastContainer toastClassName="toast" />
    </section>
  );
};

export default Application;
