import classNames from "classnames";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

const Navbar: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className={classNames("navbar", open && "is-active")}>
      <div className="navbar__burger">
        <button
          onClick={() => setOpen(!open)}
          className={classNames(
            "hamburger hamburger--collapse",
            open && "is-active"
          )}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <div className={classNames("navbar__logo", open && "is-active")}>
        <span className="navbar__text">Market Team</span>
      </div>
      <div className={classNames("navbar__button", open && "is-active")}>
        <HashLink to={"#application"} className="btn">
          стать партнером
        </HashLink>
      </div>
      <div className={classNames("navbar__container", open && "is-active")}>
        <div className="navbar__container--half">
          <HashLink to={"#privileges"} onClick={() => setOpen(false)}>
            преимущества
          </HashLink>
          <HashLink to={"#services"} onClick={() => setOpen(false)}>
            услуги
          </HashLink>
          <HashLink to={"#about"} onClick={() => setOpen(false)}>
            о нас
          </HashLink>
          <HashLink to={"#partners"} onClick={() => setOpen(false)}>
            партнеры
          </HashLink>
          <HashLink to={"#application"} onClick={() => setOpen(false)}>
            контакты
          </HashLink>
        </div>
        <div className="navbar__container--half" />
      </div>
    </nav>
  );
};

export default Navbar;
