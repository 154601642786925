import React from "react";
import { HashLink } from "react-router-hash-link";

const serviceSteps = [
  {
    title: "дизайн товаров",
    paragraph:
      "Наша команда опытных дизайнеров знает, как сделать ваш продукт выделяющимся на рынке и привлекательным для потребителей. Мы тщательно анализируем вашу нишу, изучаем тренды и потребности вашей целевой аудитории, чтобы создать уникальные и функциональные дизайнерские решения. Независимо от того, нужен ли вам редизайн существующего товара или создание нового, мы готовы воплотить ваши идеи в реальность.",
  },
  {
    title: "упаковка товаров",
    paragraph:
      "Если вам требуется профессиональная упаковка товаров, включая поиск типографии, контроль над печатью упаковки и многое другое, обратитесь к нам. Мы с удовольствием поможем вам создать упаковку, которая выделит ваш товар на рынке и привлечет внимание покупателей.",
  },
  {
    title: "фулфилмент для маркетплейсов",
    paragraph:
      "Управление логистикой и выполнение заказов на маркетплейсах может быть сложной задачей. Мы предлагаем полный спектр услуг по фулфилменту, чтобы облегчить этот процесс. Наша команда экспертов занимается приемом, упаковкой и отправкой заказов вашим клиентам, обеспечивая быструю и надежную доставку. Мы интегрируемся с вашей системой управления складом и маркетплейсом, чтобы предоставить вам полный контроль над процессом выполнения заказов. Вы можете сосредоточиться на развитии своего бизнеса, пока мы заботимся о вашей логистике.",
  },
];

const Services: React.FC = () => {
  return (
    <section
      className="section"
      id="services"
      style={{ backgroundColor: "#1f1e1e" }}
    >
      <div className="services">
        <div className="services__half">
          <span className="section__title">наши услуги</span>
          <span className="services__subtitle">
            Помогаем брендам выйти
            <br /> на новый уровень
          </span>
          <HashLink
            to={"#application"}
            className="btn btn--white"
            style={{ marginTop: "5rem" }}
          >
            стать партнером
          </HashLink>
        </div>
        <div className="services__half">
          {serviceSteps.map(
            (step: { title: string; paragraph: string }, index: number) => (
              <div className="services__card" key={index}>
                <span className="services__card--number">0{index + 1}</span>
                <span className="services__card--title">{step.title}</span>
                <p className="services__card--paragraph">{step.paragraph}</p>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Services;
