import React, { useState } from "react";
import classNames from "classnames";
import img1 from "../../img/brands/1.jpg";
import img2 from "../../img/brands/2.jpg";
import img3 from "../../img/brands/3.jpg";
import img4 from "../../img/brands/4.jpg";
import img5 from "../../img/brands/5.jpg";
import img6 from "../../img/brands/6.jpg";
import img7 from "../../img/brands/7.jpg";
import img8 from "../../img/brands/8.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

interface IBrand {
  title: string;
  background: string;
  image: string;
  label: string[];
  description1: string;
  description2: string;
}

const initialBrands: IBrand[] = [
  {
    title: "PILLZ",
    background: "#ffebdc",
    image: img1,
    label: ["01", "2022"],
    description1: "Витаминные комплексы",
    description2:
      "Это уникальный бренд высококачественных биологически активных добавок  для всей семьи. Независимо от возраста или потребностей, Pillz предлагает разнообразные продукты, предназначенные для поддержания здоровья и общего благополучия всей семьи.",
  },
  {
    title: "MIXTERRA",
    background: "#f2f2f2",
    image: img2,
    label: ["02", "2020"],
    description1: "Витаминные комплексы и уходовая косметика",
    description2:
      "Мы постоянно следим за тенденциями в косметической индустрии и обновляем нашу линейку продукции, чтобы соответствовать потребностям наших клиентов.",
  },

  {
    title: "Naturoteka",
    background: "#ffebdc",
    image: img3,
    label: ["03", "2017"],
    description1: "Витаминные комплексы и уходовая косметика ",
    description2:
      "В ассортименте Naturoteka вы найдете тщательно разработанные лечебные крема, витамины и масла для лица, созданные на основе натуральных компонентов. Каждый продукт бренда Naturoteka производится на Алтае и из 100% натуральных ингредиентов.",
  },
  {
    title: "Effilab",
    background: "#f2f2f2",
    image: img4,
    label: ["04", "2022"],
    description1: "Витаминные комплексы",
    description2:
      "Коллекция продуктов Effilab включает в себя все, что вам нужно для обеспечения всестороннего ухода за своим телом. БАДы Effilab являются отличным дополнением к вашему режиму здорового питания и физической активности.",
  },
  {
    title: "Altai Bio",
    background: "#ffebdc",
    image: img5,
    label: ["05", "2017"],
    description1: "Витаминные комплексы и лечебные крема",
    description2:
      "Мы гордимся тем, что предлагаем 100% натуральные биологически активные добавки (БАДы) и лечебную косметику, произведенные исключительно на просторах Алтая. Алтай Био - это бренд, на который можно положиться, когда речь идет о качестве и натуральности.",
  },
  {
    title: "Noemimose",
    background: "#f2f2f2",
    image: img6,
    label: ["06", "2022"],
    description1: "Линейка одежды",
    description2:
      "это бренд женской одежды, который понимает уникальность каждой женщины! Наша команда опытных дизайнеров постоянно следит за последними тенденциями в мире моды. Но то, что действительно отличает нас, - это наша любовь к деталям. Каждая вещь в нашей коллекции разрабатывается с особым вниманием к деталям, что делает каждую единицу по-настоящему особенной.",
  },
  {
    title: "Femio",
    background: "#ffebdc",
    image: img8,
    label: ["06", "2022"],
    description1: "Уходовая косметика",
    description2:
      "Наши продукты основаны на активных ингредиентах, тщательно отобранных для борьбы с разными типами проблем, такими как акне, пигментация, сухость кожи и другие. Каждый продукт Femio разработан с учетом уникальных потребностей кожи, чтобы обеспечить максимальный результат.",
  },
  {
    title: "ThinkCo",
    background: "#f2f2f2",
    image: img7,
    label: ["07", "2021"],
    description1: "Уходовая косметика",
    description2:
      "бренд корейской уходовой косметики, разрабатывающий передовые решения для всех типов кожи (проблемная, нормальная, комбинирования, зрелая). Ключевой особенностью ThinkCo являются активные ингредиенты, которые помогут вам достичь желаемого результата в уходе за лицом.",
  },
];

const Brands: React.FC = () => {
  const [step, setStep] = useState(0);
  const slider = React.useRef<any>(0);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,

    afterChange: (index: number) => {
      setStep(index);
    },
  };
  return (
    <section className="section" id="brands">
      <div className="brands__heading">
        <span className="section__title">наши бренды</span>
        <p>
          Мы постоянно следим за тенденциями в косметической индустрии <br /> и
          обновляем нашу линейку продукции, чтобы соответствовать <br />
          потребностям наших клиентов.
        </p>
      </div>

      <div className="brands">
        <Slider {...settings} ref={slider}>
          {initialBrands.map((brand: IBrand, index: number) => (
            <div key={index}>
              <div className="brands__wrap">
                <div
                  className="brands__left"
                  style={{ backgroundColor: brand.background }}
                >
                  <span className="brands__left--title">{brand.title}</span>
                  <span className="brands__left--subtitle">
                    {brand.description1}
                  </span>
                  <p className="brands__left--paragraph">
                    {brand.description2}
                  </p>
                </div>
                <img
                  alt={brand.title}
                  src={brand.image}
                  className="brands__image"
                ></img>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Brands;
