const Partners: React.FC = () => {
  return (
    <section className="section" id="partners">
      <div className="partners">
        <span className="section__title">
          мы всегда в поиске
          <br /> деловых партнеров
        </span>
        <div className="partners__main">
          <div className="partners__main--reason">
            <span>лидеры рынка по косметике и БАДам</span>
          </div>
          <div className="partners__main--reason">
            <span>лидеры рынка по косметике и БАДам</span>
          </div>
        </div>

        <div className="partners__categories">
          <div
            className="partners__categories--item"
            style={{ flexBasis: "20%" }}
          >
            <span>Уходовая косметика</span>
          </div>
          <div
            className="partners__categories--item"
            style={{ flexBasis: "70%" }}
          >
            <span>БАДы</span>
          </div>
          <div
            className="partners__categories--item"
            style={{ flexBasis: "70%" }}
          >
            <span>Бытовая химия</span>
          </div>
          <div
            className="partners__categories--item"
            style={{ flexBasis: "20%" }}
          >
            <span>Одежда</span>
          </div>

          <div
            className="partners__categories--item"
            style={{ flexBasis: "100%" }}
          >
            <span>Средства личной гигиены</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
