import React, { useEffect } from "react";
import About from "./layout/About/About";
import Application from "./layout/Application/Application";
import Footer from "./layout/Footer/Footer";
import Header from "./layout/Header/Header";
import Partners from "./layout/Partners/Partners";
import PhotoGrid from "./layout/PhotoGrid/PhotoGrid";
import Privileges from "./layout/Privileges/Privileges";
import Services from "./layout/Services/Services";
import AOS from "aos";
import "./sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
import Brands from "./layout/Brands/Brands";

const App: React.FC = () => {
  useEffect(() => {
    //scroll animations
    AOS.init({ disable: "mobile", offset: 20 });
    AOS.refresh();
    //languages
  }, []);

  return (
    <>
      <Header />
      <Privileges />
      <PhotoGrid />
      <Services />
      <Brands />
      <About />
      <Partners />
      <Application />
      <Footer />
    </>
  );
};

export default App;
