import Navbar from "./Navbar";

const Header: React.FC = () => {
  return (
    <header className="header">
      <Navbar />
      <h1 className="header__hero-title">
        Market <span>Team</span>
      </h1>
      <div className="header__first-half">
        <img src={require("../../img/heroFirst.jpg")} alt="heroSecond" />
        <h2 className="header__subtitle">
          Мы реализуем высококачественные БАДы и косметические продукты,
          произведенные исключительно в России
        </h2>
      </div>
      <div className="header__second-half">
        <img src={require("../../img/heroSecond.jpg")} alt="heroSecond" />
      </div>
    </header>
  );
};

export default Header;
