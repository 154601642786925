const PhotoGrid: React.FC = () => {
  return (
    <div className="photogrid">
      <div className="photogrid__item photogrid__item--1">
        <span>уходовая косметика</span>
      </div>
      <div className="photogrid__item photogrid__item--2">
        <span>БАДы</span>
      </div>
      <div className="photogrid__item photogrid__item--3">одежда</div>
    </div>
  );
};

export default PhotoGrid;
